<template>
    <div>
        <div class="row m-4">
            <h5>
                <font-awesome-icon
                    icon="tasks"
                    class="mr-2"
                ></font-awesome-icon>
                Manage Your Group
            </h5>
        </div>
        <b-card-group
            class="mx-4"
            columns
        >
            <GroupOptionCard
                v-for="(option, index) in manage_options.options"
                :key="`group-manage-option-${index}`"
                :option="option"
            />
        </b-card-group>
    </div>
</template>
<script>
import manage_options from "./manage_options.json"
import GroupOptionCard from './cards/GroupOptionCard.vue';

export default {
    data() {
        return { manage_options };
    },
    components: { GroupOptionCard }
}
</script>