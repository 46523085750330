<template>
    <b-card>
        <router-link
            :to="getLink(option.link)"
            class="text-dark"
        >
            <h4>
                <span
                    v-if="option.icon"
                    :is="option.icon.type"
                    :icon="option.icon.icon"
                ></span>
                {{ option.title }}
            </h4>
        </router-link>
        <div class="d-flex justify-content-around mt-3">
            <router-link
                v-for="(action, index) in option.action_links"
                :key="`group-manage-option-${index}-link-${index}`"
                :to="getLink(action.link)"
            >
                {{ action.label }}
            </router-link>
        </div>
    </b-card>
</template>
<script>
import { mapState } from "vuex";

export default {
    props: ["option"],
    methods: {
        getLink(link) {
            return { ...link, params: this.getParams(link) };
        },
        getParams(action) {
            if (!action || !action.params) return {};
            if (!Array.isArray(action.params)) return action.params;
            return Object.fromEntries(action.params.map((paramName) => [paramName, this.action_context[paramName]])) || {};
        }
    },
    computed: {
        action_context() {
            return { group_id: this.group.group_id };
        },
        ...mapState(['group'])
    }
}
</script>