<template>
    <div>
        <div
            v-if="group.group_calendar_url"
        >
            <div class="row m-4">
                <h5>
                    <font-awesome-icon
                        icon="calendar-alt"
                        class="mr-2"
                    ></font-awesome-icon>
                    Calendar
                </h5>
            </div>
            <iframe
                :src="group.group_calendar_url"
                style="border: 0"
                width="800"
                height="600"
                frameborder="0"
                scrolling="no"
            ></iframe>
        </div>
        <b-alert
            v-else
            variant="danger"
        >
            This group does not have a calendar attached.
        </b-alert>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    computed: mapState(['group'])
}
</script>