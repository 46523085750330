<template>
    <div>
        <div class="row m-4">
            <h5>
                <font-awesome-icon
                    icon="shapes"
                    class="mr-2"
                ></font-awesome-icon>
                Apps
            </h5>
        </div>
        <b-card-group
            class="mx-4"
            columns
        >
            <b-card>
                <h4>
                    <font-awesome-icon
                        icon="file-invoice"
                        class="mr-2"
                    ></font-awesome-icon>
                    Forms
                </h4>
                    
                <sc-btn-group>
                    <router-link
                        to="/forms"
                    >
                        Create
                    </router-link>
                    <router-link
                        to="/forms"
                    >
                        View
                    </router-link>
                </sc-btn-group>
            </b-card>
            <b-card>
                <h4>
                    <font-awesome-icon
                        icon="clipboard"
                        class="mr-2"
                    ></font-awesome-icon>
                    Sign-ups
                </h4>
                <sc-btn-group>
                    <router-link
                        to="/signups"
                    >
                        Create
                    </router-link>
                    <router-link
                        to="/signups"
                    >
                        View
                    </router-link>
                </sc-btn-group>
            </b-card>
        </b-card-group>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    computed: mapState(['group'])
}
</script>