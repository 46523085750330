<template>
    <div>
        <div class="row m-4">
            <h5>
                <font-awesome-icon
                    icon="file"
                    class="mr-2"
                ></font-awesome-icon>
                Files
            </h5>
        </div>
        <div class="row mx-4">
            <div class="col-sm-12">
                <base-list
                    class="mt-2"
                    listUrl="/api/admin/list-pdf-files"
                >
                    <template #content="{ item: pdfLink }">
                        <a
                            :href="pdfLink.link"
                            target="_blank"
                        >
                            <font-awesome-icon :icon="pdfLink.icon" class="mr-1"></font-awesome-icon> {{ pdfLink.title || pdfLink.key }}
                        </a>
                        <div class="float-right">
                            <a :href="pdfLink.link" target="_blank" class="mr-2">
                                <font-awesome-icon
                                    icon="external-link-alt"
                                ></font-awesome-icon>
                                View
                            </a>
                            <a :href="pdfLink.link" download>
                                <font-awesome-icon
                                    icon="download"
                                ></font-awesome-icon>
                                Download
                            </a>
                        </div>
                    </template>
                </base-list>
            </div>
        </div>
    </div>
</template>
<script>
import BaseList from '../../components/ui/BaseList.vue';
export default {
    components: { BaseList }
}
</script>