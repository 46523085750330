<template>
    <div class="home-action-previews">
        <h1 v-if="group">{{ group.group_name }}</h1>
        <group-calendar />
        <group-manage-options />
        <group-connected-apps />
        <group-files-list />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import GroupCalendar from './GroupCalendar.vue';
import GroupConnectedApps from './GroupConnectedApps.vue';
import GroupFilesList from './GroupFilesList.vue';
import GroupManageOptions from './GroupManageOptions.vue';

export default {
    components: { GroupCalendar, GroupManageOptions, GroupConnectedApps, GroupFilesList },
    computed: mapState(['group'])
}
</script>